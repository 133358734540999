.heroSection {
  background: rgb(255,166,45);
background: linear-gradient(103deg, rgba(255,166,45,1) 21%, rgba(0,0,0,1) 67%);
}


.border-gradient {
  border: 5px solid;
  border-image-slice: 1;
  border-width: 20px;
}
.border-gradient-purple {
  border-image-source: linear-gradient(to left, #1e1a1d,#ed9107);
}

.depositCard {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.depoSection {
  width: 80%;
  padding: auto;
  margin: auto;
}

.tradesSection {
  background-color: black;

  background-image: url('./assets/trade.png');
  background-position: right;
  background-size: 700px;
  background-repeat: no-repeat;
}


.tradeSectionWidth {
  width: 80%;
  padding: auto;
  margin: auto;
}

.infoSectionWidth {
  width: 80%;
  padding: auto;
  margin: auto;
}

.btgx {
  background: rgb(255,120,45);
  background: linear-gradient(13deg, rgba(255,120,45,1) 5%, rgba(235,193,57,1) 100%);
color: #fff;
font-weight: bold;
border: 1px solid #fff;
}

.footerSection {
  background-color: #ed9107;
  background-image: url("./assets/footer.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 1000px;
  
}


.infoSection {
  background-image: url("./assets/infoarea.png");
}

.card {
  margin-bottom: 5px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #f2a304;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #de800e;
  cursor: pointer;
}

.exchangesSection {
  background-color: #fff;
}

@media only screen and (max-width: 600px) {
  .tradesSection {
    background-color: black;
    background-image: none;
  }
  .footerSection {
    background-color: #ed9107;
    background-image: none;
  }
  .btgx {
    background: rgb(255,120,45);
    background: linear-gradient(13deg, rgba(255,120,45,1) 5%, rgba(235,193,57,1) 100%);
  color: #fff;
  font-weight: bold;
  border: 1px solid #fff;
  font-size: 18px;
  text-align: center;
  margin: auto;
  padding: auto;
  }
  .infoSectionWidth {
    width: 100%;
    padding: auto;
    margin: auto;
  }

  .tradeSectionWidth {
    width: 100%;
    padding: auto;
    margin: auto;
  }

  .depoSection {
    width: 100%;
    padding: auto;
    margin: auto;
  }
  .tableone tr td h5 {
    font-size: 18px;
  }
  .justifyX {
   
    width: 100%;
  }

  .imgcontrol {
    display: none;
  }
}
